import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Divider from "../components/Divider/Divider"
import Hero from "../components/Hero/Hero"
import Main from "../components/main"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials"


export const query = graphql`
{
  testimonials: allTestimonialsYaml(filter: {tags: {in: "clients"}}) {
    edges {
      node {
        title
        person
        quote
      }
    }
  }
  companyYaml {
    clients {
      logo {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      city
      name
      state
    }
    clients_explainer
  }
  aboutImageMWeb: file(relativePath: { eq: "about_bubble_mweb.png" }) {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  aboutImageDesktop: file(relativePath: { eq: "about_bubble_desktop.png" }) {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
}
`

const ClientListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 75px;
  column-gap: 50px;
  margin-top: 2rem;
`

const ClientListSectionWrapper = styled.div``

const ClientCardWrapper = styled.div`
  border-left: solid;
  border-left-color: ${({ theme }) => theme.colors.secondaryOrangeLight };
  border-bottom: solid;
  border-bottom-color: ${({ theme }) => theme.colors.secondaryOrangeLight };
  padding: 5px;
  flex-basis: 35%;
  @media (min-width: 768px) {
    flex-basis: 20%;
  }
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const H3 = styled.h3`
  padding: 0 0 1rem 0;
`

const ClientLogo = styled(Img)`
`

const ClientLocation = styled.p``

const ClientDescription = styled.div`
  text-align: center;
  margin-top: 1rem;
`

const ClientCard = ({ client, key }) => {
  const location = client.city != null ? `${client.city}, ${client.state}` : client.state
  return (
    <ClientCardWrapper key={key}>
      <ClientLogo durationFadeIn="0" fixed={client.image} alt={`Logo for ${client.name}`} />
      <ClientDescription>
        <ClientLocation>{location}</ClientLocation>
      </ClientDescription>
    </ClientCardWrapper>
  )
}

const ClientList = ({ title, clients }) => {
  const clientElements = clients.map((client, idx) => {
    return <ClientCard key={idx} client={client} />
  })
  return (
    <ClientListSectionWrapper>
      <H3>{title}</H3>
      <ClientListWrapper>
        {clientElements}
      </ClientListWrapper>
    </ClientListSectionWrapper>
  )
}


const ClientsPage = ({ data }) => {

  const clients = data.companyYaml.clients.map(client => {
    return {
      name: client.name,
      city: client.city,
      state: client.state,
      image: client.logo.childImageSharp.fixed,
    }
  })

  return (
    <>
      <SEO title="Our Clients" />
      <Hero title="Our Clients" subtitle="" backgroundImageName="about" />
      <Main>
        <ClientList title={data.companyYaml.clients_explainer} clients={clients} />
        <Divider />
        <Testimonials title="Testimonials" testimonials={data.testimonials.edges.map(el => el.node)}/>
      </Main>
    </>
  )
}

export default ClientsPage
